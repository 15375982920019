<template>
  <div id="storeSettled">
    <div class="shop-logo">
      <p>店铺LOGO</p>
      <input @change="logoUpload" ref="logoInput" type="file" accept="image/*" />
      <img @click="uploadLogo" v-lazy="logoSrc" alt />
    </div>

    <div class="info-item">
      <p class="title">店铺名称</p>
      <input v-model="shopName" type="text" placeholder="请填写您的店铺名称" />
    </div>

    <div class="info-item">
      <p class="title">店长姓名</p>
      <input v-model="masterName" type="text" placeholder="请输入您的姓名" />
    </div>

    <div class="info-item">
      <p class="title">联系电话</p>
      <input
        v-model="tel"
        oninput="this.value = value.replace(/[^\d]/g,''); if(value.length>11)value=value.slice(0,11)"
        type="number"
        placeholder="请输入您的手机号码"
      />
    </div>

    <div class="info-item">
      <p class="title">所在城市</p>
      <div class="right-box" @click="showArea = true">
        <p :class="address === '点击选择' ? '' : 'active'">{{address}}</p>
        <img v-if="address === '点击选择'" src="@images/right-arrow.png" alt />
      </div>
    </div>

    <div class="info-item">
      <p class="title">详细地址</p>
      <input v-model="detailAddress" type="text" placeholder="请输入您的详细地址" />
    </div>

    <div class="license-box">
      <p class="title">
        上传营业执照
        <input type="file" @change="licenseUpload" ref="licenseInput" accept="image/*" />
      </p>
      <img @click="uploadLicense" class="upload-pic" v-lazy="licenseSrc" alt />

      <p class="tips-title">上传说明：</p>
      <p class="tips">1. 企业上传营业执照，若办理三证合一请上传最新营业执照。</p>
      <p class="tips">2. 社会组织团体请上传等级证书。</p>
      <p class="tips">3. 需提供原件照片或加盖公章的扫描件。</p>
    </div>

    <div class="id-box">
      <p class="title">
        上传身份证照片
        <input type="file" @change="IDfrontUpload" ref="IDfrontInput" accept="image/*" />

        <input type="file" @change="IDbackUpload" ref="IDbackInput" accept="image/*" />
      </p>
      <div class="upload-box">
        <img @click="uploadIdFront" class="upload-pic" v-lazy="IDfrontSrc" alt />
        <img @click="uploadIdBack" class="upload-pic" v-lazy="IDbackSrc" alt />
      </div>
      <p class="tips-title">上传说明：</p>
      <p class="tips">1. 请对应所填写的法人/运营者信息上传其有效身份证件。</p>
      <p class="tips">2. 请确保身份证件信息清晰可见，且需对应提示位置上传正反面。</p>
    </div>

    <div class="shop-info">
      <p class="title">店铺简介 (选填)</p>

      <div class="area-box">
        <textarea v-model="reason" @input="reasonInput" placeholder="请简单介绍一下您的店铺信息，内容不超过200字"></textarea>
        <p class="tips">{{reasonNum}}</p>
      </div>
    </div>

    <div class="submit-btn" @click="submit">提交</div>

    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
    </van-popup>
  </div>
</template>

<script>
import "../common/css/theme.scss";
import { Toast } from "vant";
import { getStordId, storeSettled } from "../api/requist";
import area from "../common/js/area";
export default {
  components: {},
  props: [],
  data() {
    return {
      reasonNum: "0/200",
      IDbackSrc: require("@images/id-back.png"),
      IDfrontSrc: require("@images/id-front.png"),
      areaList: area,
      showArea: false,
      licenseSrc: require("@images/upload-one.png"),
      logoSrc: require("@images/upload.png"),
      logoFile: "",
      licenseFile: "",
      IDbackFile: "",
      IDfrontFile: "",
      shopName: "",
      masterName: "",
      tel: "",
      detailAddress: "",
      address: "点击选择",
      reason: "" //简介
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // alert(document.querySelector('html').clientWidth);
    // alert(document.querySelector('html').style.fontSize);
    // alert(document.querySelector('.submit-btn').clientWidth);
    //  alert(document.querySelector('.submit-btn').style.fontSize);
  },
  watch: {},
  computed: {},
  methods: {
    getCode() {
      // 静默授权，
      var code = "";
      var local = window.location.href; // 获取页面url
      var appid = this.$root.wxAppId;
      code = this.getUrlCode().code; // 截取code
      if (code == null || code === "") {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
      } else {
        return this.getMerchIdFn(code);
      }
    },

    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = {};
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    getMerchIdFn(code) {
      let that = this;
      let formData = new FormData();
      if (localStorage.getItem("session_key")) {
        formData.append("session_key", localStorage.getItem("session_key"));
      } else {
        formData.append("code", code);
      }
      return getStordId(formData)
        .then(res => {
          if (res.data) {
            if (res.data.merch_id) {
              localStorage.setItem("merch_id", res.data.merch_id);
            }

            if (res.data.openid) {
              localStorage.setItem("session_key", res.data.openid);
            }

            if (res.data.id) {
              localStorage.setItem("merch_id", res.data.id);
            }
          }

          if (res.code == 203 && that.$route.query.again != 1) {
            that.$router.replace({
              path: "/settledResult",
              query: {
                type: 2,
                reason: res.data.reasons_refusal
              }
            });
          }

          if (res.code == 204) {
            that.$router.replace({
              path: "/settledResult",
              query: {
                type: 3
              }
            });
          }

          if (res.code == 205) {
            that.$router.replace({
              path: "/settledResult",
              query: {
                type: 1
              }
            });
          }
        })
        .catch(() => {
          return false;
        });
    },
    init() {
      // localStorage.* 3.75pxoveItem("merch_id");
      if (localStorage.getItem("session_key")) {
        this.getMerchIdFn();
      } else {
        this.getCode();
      }
    },
    reasonInput() {
      if (this.reason.length >= 200) {
        this.reason = this.reason.slice(0, 200);
      }
      this.reasonNum = this.reason.length + "/200";
    },
    uploadLogo() {
      this.$refs.logoInput.dispatchEvent(new MouseEvent("click"));
    },

    uploadLicense() {
      this.$refs.licenseInput.dispatchEvent(new MouseEvent("click"));
    },

    uploadIdFront() {
      this.$refs.IDfrontInput.dispatchEvent(new MouseEvent("click"));
    },

    uploadIdBack() {
      this.$refs.IDbackInput.dispatchEvent(new MouseEvent("click"));
    },

    logoUpload(e) {
      let that = this;
      var file = e.target.files[0];
      // console.log(file.size);
      // if (file.size > 1024 * 1024 * 3) {
      //   return Toast("图片大小不能超过3M");
      // }
      this.licenseFile = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function(e) {
          //e.target.result就是最后的路径地址
          that.logoSrc = e.target.result;
        };
      }
    },

    licenseUpload(e) {
      let that = this;
      var file = e.target.files[0];
      // if (file.size > 1024 * 1024 * 3) {
      //   return Toast("图片大小不能超过3M");
      // }
      this.logoFile = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function(e) {
          //e.target.result就是最后的路径地址
          that.licenseSrc = e.target.result;
        };
      }
    },
    IDfrontUpload(e) {
      let that = this;
      var file = e.target.files[0];
      // if (file.size > 1024 * 1024 * 3) {
      //   return Toast("图片大小不能超过3M");
      // }
      this.IDfrontFile = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function(e) {
          //e.target.result就是最后的路径地址
          that.IDfrontSrc = e.target.result;
        };
      }
    },

    IDbackUpload(e) {
      let that = this;
      var file = e.target.files[0];
      // if (file.size > 1024 * 1024 * 3) {
      //   return Toast("图片大小不能超过3M");
      // }
      this.IDbackFile = e.target.files[0];
      if (window.FileReader) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function(e) {
          //e.target.result就是最后的路径地址
          that.IDbackSrc = e.target.result;
        };
      }
    },
    onConfirm(values) {
      this.address = values.map(item => item.name).join("/");
      this.showArea = false;
    },

    async submit() {
      let that = this;
      if (!this.licenseFile) {
        return Toast("请上传店铺LOGO");
      }

      if (this.shopName === "") {
        return Toast("请输入店铺名称");
      }

      if (this.masterName === "") {
        return Toast("请输入您的姓名");
      }

      if (this.tel === "") {
        return Toast("请输入联系电话");
      }

      if (!/^1[3456789]\d{9}$/.test(this.tel)) {
        return Toast("请输入正确的联系电话");
      }

      if (this.address === "点击选择") {
        return Toast("请选择所在城市");
      }

      if (this.detailAddress === "") {
        return Toast("请输入详细地址");
      }

      if (!this.logoFile) {
        return Toast("请上传营业执照");
      }

      if (!this.IDfrontFile) {
        return Toast("请上传身份证正面");
      }

      if (!this.IDbackFile) {
        return Toast("请上传身份证背面");
      }

      Toast.loading({
        message: "上传中...",
        forbidClick: true
      });

      let formData = new FormData();
      formData.append(
        "session_key",
        localStorage.getItem("session_key")
          ? localStorage.getItem("session_key")
          : ""
      );
      formData.append(
        "merch_id",
        localStorage.getItem("merch_id") ? localStorage.getItem("merch_id") : ""
      );
      formData.append("market_name", this.shopName);
      formData.append("shopowner_name", this.masterName);
      formData.append("telephone", this.tel);
      formData.append("s_province", this.address.split("/")[0]);
      formData.append("s_city", this.address.split("/")[1]);
      formData.append("s_county", this.address.split("/")[2]);
      formData.append("market_introduction", this.reason);
      formData.append("market_logo", this.licenseFile);
      formData.append("id_positive", this.IDfrontFile);
      formData.append("id_back", this.IDbackFile);
      formData.append("address", this.detailAddress);
      formData.append("business_license", this.logoFile);
      let res = await storeSettled(formData);
      Toast.clear();
      if (res.code === 200) {
        Toast({
          message: "上传成功",
          duration: 1000,
          onClose: function() {
            that.$router.replace({
              path: "/settledResult",
              query: {
                type: 3
              }
            });
          }
        });
      } else {
        Toast("上传失败");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../common/css/theme.scss";
#storeSettled {
  width: 100%;
  padding-bottom: 8* 3.75px;
  padding-top: 3.2* 3.75px;
  box-sizing: border-box;
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .shop-logo {
    height: 18.93* 3.75px;
    padding: 0 4.27* 3.75px;
    margin-bottom: 3.2* 3.75px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
    }
    input {
      height: 0;
      width: 0;
    }
    img {
      object-fit: cover;
      border-radius: 50%;
      height: 16* 3.75px;
      width: 16* 3.75px;
    }
  }

  .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.27* 3.75px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    height: 9.6* 3.75px;
    border-bottom: 1px solid rgba(245, 247, 250, 1);
    .title {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
    }

    .right-box {
      align-items: center;
      display: flex;
      p {
        margin-right: 3.2* 3.75px;
        color: #c0c4cc;
        font-size: 3.73* 3.75px;
        &.active {
          color: #303133;
          margin-right: 0;
        }
      }
      img {
        width: 1.55* 3.75px;
        height: 2.71* 3.75px;
      }
    }

    input {
      height: 5.33* 3.75px;
      color: #303133;
      font-size: 3.73* 3.75px;
      border: none;
      outline: none;
      text-align: right;
      &::placeholder {
        color: rgba(192, 196, 204, 1);
        font-size: 3.73* 3.75px;
      }
    }
  }

  .license-box {
    height: 49.6* 3.75px;
    margin-top: 3.2* 3.75px;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    padding: 3.2* 3.75px 4.8* 3.75px 0;
    box-sizing: border-box;
    .title {
      color: rgba(48, 49, 51, 1);
      font-size: 3.73* 3.75px;
      text-align: left;
    }

    .upload-pic {
      object-fit: cover;
      margin: 3.2* 3.75px 0 0;
      display: block;
      height: 16.53* 3.75px;
      width: 29.33* 3.75px;
    }

    input {
      display: inline-block;
      visibility: hidden;
      padding: 0;
      border: none;
      height: 0;
      width: 0;
    }

    .tips-title {
      margin-top: 3.2* 3.75px;
      color: #899cc2;
      line-height: 3.73* 3.75px;
      text-align: left;
      font-size: 2.67* 3.75px;
    }

    .tips {
      line-height: 3.73* 3.75px;
      text-align: left;
      color: #606266;
      font-size: 2.67* 3.75px;
    }
  }

  .id-box {
    height: 45.87* 3.75px;
    margin-top: 3.2* 3.75px;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    padding: 3.2* 3.75px 4.8* 3.75px 0;
    box-sizing: border-box;
    .title {
      color: rgba(48, 49, 51, 1);
      font-size: 3.73* 3.75px;
      text-align: left;
    }

    .upload-box {
      display: flex;
      img {
        &:first-child {
          margin-right: 1.6* 3.75px;
        }
      }
    }

    .upload-pic {
      margin: 3.2* 3.75px 0 0;
      display: block;
      height: 16.53* 3.75px;
      width: 29.33* 3.75px;
    }

    input {
      display: inline-block;
      visibility: hidden;
      padding: 0;
      border: none;
      height: 0;
      width: 0;
    }

    .tips-title {
      margin-top: 3.2* 3.75px;
      color: #899cc2;
      line-height: 3.73* 3.75px;
      text-align: left;
      font-size: 2.67* 3.75px;
    }

    .tips {
      line-height: 3.73* 3.75px;
      text-align: left;
      color: #606266;
      font-size: 2.67* 3.75px;
    }
  }

  .shop-info {
    margin-top: 3.2* 3.75px;
    width: 100%;
    padding: 2.13* 3.75px 4.27* 3.75px 0;
    box-sizing: border-box;
    height: 51.2* 3.75px;
    background: rgba(255, 255, 255, 1);
    .title {
      color: rgba(96, 98, 102, 1);
      font-size: 3.73* 3.75px;
      text-align: left;
      margin-bottom: 2.13* 3.75px;
    }

    .area-box {
      margin: 0 auto;
      width: 91.47* 3.75px;
      height: 37.33* 3.75px;
      background: rgba(245, 247, 250, 1);
      border-radius: 1* 3.75px;
      padding: 3.2* 3.75px;
      box-sizing: border-box;
      textarea {
        width: 100%;
        height: 21.33* 3.75px;
        font-size: 3.73* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: #303133;
        outline: none;
        border: none;
        resize: none;
        background: rgba(245, 247, 250, 1);
        font-size: 3.73* 3.75px;
        &::placeholder {
          color: #909399;
          font-size: 3.73* 3.75px;
        }
      }

      .tips {
        text-align: right;
        margin-top: 5.07* 3.75px;
        font-size: 3.2* 3.75px;
        font-weight: 400;
        line-height: 4.27* 3.75px;
        color: rgba(48, 49, 51, 1);
      }
    }
  }

  .submit-btn {
    width: 91.47* 3.75px;
    height: 10.67* 3.75px;
    background: $theme-color;
    border-radius: 1* 3.75px;
    font-size: 3.73* 3.75px;
    line-height: 10.67* 3.75px;
    text-align: center;
    margin: 8* 3.75px auto 0;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
